<template>
  <div>
    <NuxtLayout name="default">
      <div class="mx-auto w-full max-w-screen-2xl grow flex-wrap lg:flex">
        <div
          class="flex flex-1 flex-col py-6 px-4 sm:px-6 md:py-12 lg:w-6/12 lg:flex-none lg:justify-center lg:py-16"
        >
          <div
            class="mx-auto flex w-full max-w-xl flex-col rounded-2xl bg-white
            p-6 shadow-light md:min-h-[700px] md:max-w-2xl md:p-12 lg:px-20 lg:py-12"
          >
            <div class="mb-16 flex items-center justify-between">
              <NuxtLink to="/">
                <img
                  src="/mogelijk-logo.svg"
                  alt="Mogelijk vastgoed"
                  class="w-32 md:w-60"
                />
              </NuxtLink>
              <slot name="header-right">
                <NuxtLink
                  to="https://mogelijk.nl/"
                  title="Ga naar de Mijn Mogelijk omgeving"
                  class="text-blue-900 hover:underline"
                  external
                >
                  mogelijk.nl
                </NuxtLink>
              </slot>
            </div>
            <slot />
          </div>
        </div>
        <slot name="right">
          <div
            class="relative hidden w-0 flex-1 items-center justify-center p-6 lg:flex"
          >
            <img src="/mogelijk-deal-handshake.svg" alt="mogelijk" />
          </div>
        </slot>
      </div>
    </NuxtLayout>
  </div>
</template>
